<template>
  <div class="h-10 w-full bg-gray-900">

  </div>
</template>

<script>
export default {
  name: "foot",
  data(){
    return{

    }
  },

}
</script>

<style scoped>

</style>