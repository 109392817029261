<template>
  <div>
    <div class="flex flex-col ">
      <div class="overflow-x sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="w-9/12 m-auto border border-blue-300 rounded">
              <thead class="border-b">
              <tr class="bg-blue-500 text-white">
                <th scope="col" class="text-sm xs:hidden md:block font-medium  px-6 py-4 text-left">
                  Désignation
                </th>
                <th scope="col" class="text-sm font-medium px-2 py-4 text-left">
                  PU HT
                </th>
                <th scope="col" class="text-sm font-medium  px-2 py-4 text-left">
                  QTE
                </th>
                <th colspan="2" scope="col" class="text-sm font-medium  px-2 py-4">
                  MONTANT
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="border-b">
                <td class="px-6 xs:hidden md:block py-4 whitespace-nowrap text-sm font-medium text-gray-900">Achat de Volume de sms</td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{datas.prix_unitaire}}
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{datas.quantite}}
                </td>
                <td class="text-right pr-4 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{ datas.montant = datas.prix_unitaire*datas.quantite}}
                </td>
              </tr>


              <tr class="bg-white border-b">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 xs:hidden md:block"> </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  DA 2%
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                </td>
                <td class="text-right pr-4 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{datas.da = this.$route.params.da}}
                </td>
              </tr>


              <tr class="bg-white border-b">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 xs:hidden md:block"></td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  TVA 19.25%
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                </td>
                <td class="text-right pr-4 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{datas.tva = this.$route.params.tva}}
                </td>
              </tr>

              <tr class="bg-white border-b">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 xs:hidden md:block"></td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  TOTAL TTC
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">

                </td>
                <td class=" text-right pr-4 text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {{this.$route.params.total_ttc}}
                </td>
              </tr>

              <tr class="bg-white border-b">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 xs:hidden md:block"></td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  BONUS SMS
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  0
                </td>
                <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                </td>
              </tr>

              </tbody>

              <tfoot>
              <tr class="h-10 bg-blue-500 text-white">
                <td></td>
                <td>TOTAL</td>
                <td>{{datas.quantite}}</td>
                <td class="text-right pr-4">{{this.$route.params.total_ttc}} XAF</td>
              </tr>
              </tfoot>
            </table>
            <loading v-if="load" class="m-auto"/>
            <div class="w-max m-auto">
              <span class="text-lg">payer avec ...</span>
              <div class="flex space-x-5">
                <img class="cursor-pointer w-32" @click="payer('om')" :src="om" alt="">
                <img class="cursor-pointer w-32" @click="payer('mtn')" :src="mtn" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import om from "../assets/img/ompaylogo.png"
import mtn from "../assets/img/mtn.png"
import axios from "axios";
import loading from "../components/Loading";

export default {

  name: "detail",
  data(){
    return{
      datas : {
        prix_unitaire : this.$route.params.ht,
        quantite : 0,
        montant:0,
        da :0,
        tva : 0
      },
      om,
      mtn,
      load:false
    }
  },
  components:{
    loading
  },
  mounted() {
    this.datas.quantite = this.$route.params.qte
  },
  methods: {
    payer(str1) {
      this.load = true
      if(str1 === "om"){
        axios.post("https://sms.nexah.net/api/v1/sliimpay-with-om",{
          transaction_id:this.$route.params.transaction_id,
          invoice_id : this.$route.params.invoice_id,
        }).then((response) => {
          this.load = false
          window.location = response.data.pay_url
          console.log(response.data)
        }).catch((err) => {
          this.load = false
          console.log(err.message)
        })
      }else{
        axios.post("https://sms.nexah.net/api/v1/sliimpay-with-mtn",{
          transaction_id:this.$route.params.transaction_id,
          invoice_id : this.$route.params.invoice_id,
          phone:this.$route.params.phone,
        })
        .then((response) => {
          console.log(this.$route.params.phone)
          console.log(response.data)
          alert("Veillez validez la transaction sur votre téléphone avec *126#")
          this.load = false
        }).catch((err) => {
          console.log(err.message)
          this.load = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>