<template>
  <navbar/>
  <transition name="fade" mode="out-in">
    <router-view/>
  </transition>
  <foot/>
</template>

<script>
import navbar from "./components/navbar";
import foot from "./components/foot";
export default {
  name:'App',
  components:{
    navbar,
    foot
  }
}
</script>

<style>
@import "assets/css/anime.css";
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;

}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
