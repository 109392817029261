<template>
  <div class="w-full h-full">
    <div class="parent">
      <div class="enfant1">
        <div
            class="
            bg-white
            rounded
            shadow-lg
            mt-4
            mx-auto

            xl:w-96
            sm:w-40
            md:w-96
            lg:w-96
          ">
          <div class="mx-auto px-4 py-8 sm:px-10">
            <div class="relative mt-6">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300"></div>
              </div>
              <div class="relative flex justify-center text-sm leading-5">
                <span class="px-2 text-lg font-bold text-gray-500 bg-white">
                  Promo SMS Slim
                </span>
              </div>
            </div>

            <div class="mt-6">
              <div class="w-full space-y-6">
                <div class="w-full ">

                  <!--                  Debut champ nom entreprise-->
                  <div class="relative">
                    <h3 class="text-gray-400 text-base ">
                      Nom de l'entreprise
                    </h3>
                    <input
                        v-model="datas.company"
                        type="text"
                        id="nom_entreprise"
                        class="
                        rounded
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-full
                        py-2
                        mt-2
                        px-4
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-base
                        focus:outline-none
                        focus:ring-2 focus:ring-blue-600
                        focus:border-transparent
                      "
                        required
                        placeholder="Ex: Sliim"
                    />
                  </div>
                  <!--                  FIn champ nom entreprise-->

                  <!--                  CHAMP EMAIL-->
                  <div class="relative mt-5">
                    <h3 class="text-gray-400 text-base ">
                      Email
                    </h3>
                    <input
                        v-model="datas.email"
                        type="text"
                        id="Email"
                        class="
                        rounded
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-full
                        py-2
                        mt-2
                        px-4
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-base
                        focus:outline-none
                        focus:ring-2 focus:ring-blue-600
                        focus:border-transparent
                      "
                        required
                        placeholder=""
                    />
                  </div>

                  <!--                  CHAMP TELEPHONE-->
                  <div class="relative mt-5">
                    <h3 class="text-gray-400 text-base ">
                      Téléphone
                    </h3>
                    <input
                        v-model="datas.phone"
                        type="text"
                        id="PHONE"
                        class="
                        rounded
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-full
                        py-2
                        mt-2
                        px-4
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-base
                        focus:outline-none
                        focus:ring-2 focus:ring-blue-600
                        focus:border-transparent
                      "
                        required
                        placeholder=""
                    />
                  </div>


                  <!--                  CHAMP NOM-->
                  <div class="relative mt-5">
                    <h3 class="text-gray-400 text-base ">
                      Nom
                    </h3>
                    <input
                        v-model="datas.name"
                        type="text"
                        id="NOM"
                        class="
                        rounded
                        border-transparent
                        flex-1
                        appearance-none
                        border border-gray-300
                        w-full
                        py-2
                        mt-2
                        px-4
                        bg-white
                        text-gray-700
                        placeholder-gray-400
                        shadow-sm
                        text-base
                        focus:outline-none
                        focus:ring-2 focus:ring-blue-600
                        focus:border-transparent
                      "
                        required
                        placeholder=""
                    />
                  </div>


                  <!--                  CHAMP VOLUME-->
                  <div class="relative mt-5">
                    <h3 class="text-gray-400 text-base ">
                      Volume de sms
                    </h3>
                    <div class="flex mt-2 justify-center">
                      <div class="mb-3 ">
                        <select
                            v-model="datas.nbsms"
                            class="form-select appearance-none
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            aria-label="Default select example">
                          <option value="500">500 sms</option>
                          <option value="1000">1000 sms</option>
                          <option value="3000">3000 sms</option>
                          <option value="4000">4000 sms</option>
                          <option value="5000">5000 sms</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <loading v-if="load" class="m-auto"/>

                  <div class="mt-3">
                    <button type="button" class="px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs
                    leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0
                    transition duration-150 w-full ease-in-out" @click="payer()">PAYER
                    </button>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    footer-->
  </div>
</template>

<script>
import axios from "axios"
import loading from "../components/Loading";

export default {
  name: 'HelloWorld',
  data() {
    return {
      datas: {
        company: '',
        email: '',
        phone: '',
        name: '',
        nbsms: ''
      },
      load: false
    }
  },
  components: {
    loading
  },
  methods: {
    payer() {
      if (this.datas.nbsms.trim() == "" ||
          this.datas.company.trim() == "" ||
          this.datas.email.trim() == "" ||
          this.datas.phone.trim() == "" ||
          this.datas.name.trim() == "") {
        alert("Veillez remplir tous les champs")
      } else {
        this.load = true
        console.log(this.datas.nbsms)
        axios.post("https://sms.nexah.net/api/v1/sliimpay-sms", {
          company: this.datas.company,
          email: this.datas.email,
          phone: this.datas.phone,
          name: this.datas.name,
          nbsms: this.datas.nbsms,
        })
            .then((response) => {
              console.log(response.data.data)
              this.load = false
              let newData = response.data.data
              newData.phone = this.datas.phone
              this.$router.push({name: 'detail', params: newData})
            }).catch((error) => {
          console.log(error)
          this.load = false
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
